import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/index.vue'
import PreguntasFrecuentes from '../components/PreguntasFrecuentes/index.vue'
/* import Checkout from '@/components/checkout/index.vue' */
import Checkout2 from '@/components/checkout/Checkout2.vue'
import Sellers from '@/components/Sellers'
import Register from '@/components/Login/Register'
import Smartband from '@/components/Smartband/Smartband.vue'
import Iduam600 from '@/components/Smartband/Iduam600.vue'
import Iduam from '@/components/Smartband/Iduam.vue'
import EstadoOrden from '@/components/checkout/EstadoOrden.vue'
import Catalog from '@/components/Catalog.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/preguntas_frecuentes',
    name: 'PreguntasFrecuentes',
    component: PreguntasFrecuentes
  },
  {
    path: '/catalogo',
    name: 'Catalog',
    component: Catalog
  },
/*   {
    path: '/Checkout',
    name: 'Checkout',
    component: Checkout
  }, */
  {
    path: '/finalizar-compra',
    name: 'Checkout2',
    component: Checkout2
  },
  {
    path: '/sellers',
    name: 'Sellers',
    component: Sellers
  },
  {
    path: '/register-seller',
    name: 'Register',
    component: Register
  },
  {
    path: '/T-68',
    name: 'Smartband',
    component: Smartband
  },
  {
    path: '/E-600',
    name: 'Iduam600',
    component: Iduam600
  },
  {
    path: '/Iduam',
    name: 'Iduam',
    component: Iduam
  },
  {
    path: '/estado-orden',
    name: 'EstadoOrden',
    component: EstadoOrden
  }

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
