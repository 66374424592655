<template>
  <div class="navbar-no-shadow wf-section">
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      class="navbar-no-shadow-containerhtml w-nav"
    >
      <div class="containerhtml-regular">
        <div class="navbar-wrapper">
          <a href="/" class="navbar-brand w-nav-brand"
            ><img src="images/logo.svg" loading="lazy" width="140" alt=""
          /></a>
          <nav role="navigation" class="nav-menu-wrapper w-nav-menu">
            <ul role="list" class="nav-menu w-list-unstyled">
              <li>
                <a
                  href="http://www.iduam.com/"
                  class="nav-link"
                  target="_self"
                  >Web Iduam</a
                >
              </li>
              <li>
                <a href="/#comofunciona" class="nav-link">Como Funciona</a>
              </li>
<!--               <li><a @click="$router.push('/Smartband')" class="nav-link">Smartband</a></li> -->
              <!-- <li><a href="/#" class="nav-link">Monitoreo</a></li> -->
              <li>
                <a
                  href="https://www.youtube.com/channel/UCBnz1KlUdfLt1misGsGk_lQ"
                  class="nav-link"
                  target="_self"
                  >VideoTutorial</a
                >
              </li>
              <!-- <li>
                <a
                  href="https://ventasadultomayor.sosclick.cl/"
                  class="nav-link"
                  target="_self"
                  >Adulto Mayor</a
                >
              </li>
              <li>
                <a
                  href="http://ventasboton.sosclick.cl/"
                  class="nav-link"
                  target="_self"
                  >Botón Adulto Mayor</a
                >
              </li>
              <li>
                <a href="/preguntas_frecuentes" class="nav-link"
                  >Preguntas Frecuentes</a
                >
              </li> -->
              <li class="mobile-margin-top-10" v-show="isMobile">
                <a
                  href="https://www.click2call.cl/?ShareLink=5"
                  class="nav-link"
                  target="_self"
                  >Contacto</a
                >
              </li>
              <li class="mobile-margin-top-10" v-show="!isMobile">
                <div class="nav-button-wrapper">
                  <a
                    href="https://www.click2call.cl/?ShareLink=5"
                    class="button-primary w-button"
                    target="_self"
                    >Contactar</a
                  >
                </div>
              </li>
            </ul>
          </nav>


          <nav role="navigation" class="nav-menu-wrapper w-nav-menu">
            <ul role="list" class="nav-menu w-list-unstyled">
        <v-btn
          text
          color="white"
          class="mx-2"
          @click="$router.push('/catalogo')"

          style="z-index:1;"
        >
        <div class="d-flex align-center" style="gap: 8px;">
          <v-icon>mdi-storefront</v-icon>
          Tienda
        </div>
        </v-btn>
      <div @click="$router.push('/finalizar-compra')" style="cursor: pointer; z-index: 123;">
      <v-badge
        style=" font-weight: 600;"
        bordered
        color="error"
        :content="cart_total"
        overlap
        class="mr-5"
      >
        <v-icon color="white" style="font-size: 34px;">mdi-cart-variant</v-icon>
      </v-badge>
      </div>
            </ul>
          </nav>
          <div class="menu-button w-nav-button">
            <div class="w-icon-nav-menu" style="color: #FFFF;"></div>
          </div>

          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    isMobile: false,
  }),
  computed: {
      cart_total() {
        const products = this.$store.state.products.reduce((acc, producto) => acc + producto.qty, 0);
        const plans = this.$store.state.cart_plans.length
        return products + plans || '0';
      }
    },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 992;
    },
  },
};
</script>
<style>
</style>