const endpoints = {
    login: '/api/auth/login',
    userData: '/api/userData',
    user: '/api/app/user/details',
    videollamada: '/api/calls/videoCall',
    videoEmail: '/api/calls/videoMail',
    speciality: '/api/company/specialities',
    callEvent: '/api/events',
    postFormContact: '/api/attention/contact',
    paisesActivos: '/api/auth/getPaisesActivos',
    regionesActivas: '/api/auth/getRegionesActivas',
    comunasActivas: '/api/auth/getComunasActivas',

//payment
    getPaymentValue: '/api/app/planes/getToPayData',
    startPaymentPlan: '/api/app/pagos/createPurchaseOrder', // /app/pagos/createPurchaseOrder?product_id=1&qty=1
    getOrderStatusByToken: '/api/auth/getOrderStatusByToken',
		getFinalPaymenInfo: '/api/pagos/getFinalPaymenInfo',

//Registro
    register: '/api/auth/signup',
    getToken: 'api/token',
		webSignUp: 'api/auth/webSignUp',

//SERVICIOS
    getServices: '/api/company/serviceStatus',

    fileUpload: 'api/calls/videoCall/{videoCallID}/publicAttachment',
    getFilePublicUrl: 'api/attachments/',

	//Sellers
	addSellerApplication: 'api/sellers/addSellerApplication',
	getSellerApplicationByID: 'api/sellers/getSellerApplicationByID',
	getSellerByCode: 'api/sellers/getSellerByCode',
	askForSellerContact: 'api/sellers/askForSellerContact',
    //pagos
    listadoCiudadesDestino: 'api/app/pagos/listadoCiudadesDestino',
    getCartData: 'api/app/pagos/getCartData',
    createPurchaseOrder: 'api/app/pagos/createPurchaseOrder',
    getPlanes: 'api/app/planes/getPlanes',
    //ruleta
	getRouletteOptions: '/api/marketing/getRouletteOptions',
	getRouletteResult: '/api/marketing/getRouletteResult',
	createPurchaseOrderCouponBracelet: '/app/pagos/createPurchaseOrderCouponBracelet',
}

export {endpoints}
